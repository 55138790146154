<template>
  <v-container>
    <v-row>
      <v-col class="px-6" cols="12" md="12">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="mt-5">Checklisten prüfen</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <h3 class="my-4">Zu prüfende Checklisten nach Fahrzeugen:</h3>


            <v-select
                :items="filters"
                v-model="filter"
                item-text="name"
                item-value="key"
                label="Filter"
                class="my-4"
                @change="getVehicles"
            ></v-select>

            <v-expansion-panels
                v-model="panel"
            >
              <v-expansion-panel
                  v-for="vehicle in vehicles"
                  :key="vehicle._id"
              >
                <v-expansion-panel-header>
                  <v-row no-gutters>
                    <v-col cols="9">
                      {{ vehicle.name }}
                    </v-col>
                    <v-col
                        cols="3"
                        class="text--secondary"
                    >
                      <v-row
                          no-gutters
                          style="width: 100%"
                      >
                        <v-col cols="6">

                        </v-col>
                        <v-col cols="6">
                          <span>
                            {{ getItemCount(vehicle.plists).done }}
                          </span>
                          <v-icon color="success">
                            mdi-check-circle
                          </v-icon>
                          <span class="ml-2">
                            {{ getItemCount(vehicle.plists).error }}
                          </span>
                          <v-icon color="red">
                            mdi-close-circle
                          </v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>


                  <v-expansion-panels
                  accordion>
                    <v-expansion-panel
                        v-for="list in vehicle.plists"
                        :key="list._id"
                    >
                      <v-expansion-panel-header>
                        <v-row no-gutters>
                          <v-col cols="1">
                            <v-icon v-if="list.uncheckedItems" color="red">mdi-clipboard-list</v-icon>
                            <v-icon v-else color="success">mdi-clipboard-list</v-icon>
                          </v-col>
                          <v-col cols="11">
                            {{ list.title }}
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row no-gutters>

                          <v-col cols="5">
                            <p v-if="!list.uncheckedItems">Alle Checklisten-Items sind in Ordnung. Liste kann
                              geschlossen werden.</p>
                            <div v-else>
                              <div v-for="item in list.items" :key="item._id">
                                <div v-if="!item.is_ok">
                                  <p>
                                    <v-icon>mdi-close-circle-outline</v-icon>
                                    <strong>{{ item.title }}</strong>: {{ item.remark }} <span
                                      v-if="item.editor">({{ item.editor }})</span>
                                  </p>

                                </div>
                              </div>
                            </div>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-divider
                              vertical
                              class="mx-4"
                          ></v-divider>
                          <v-col cols="4" v-if="list.editor && list.updatedAt">
                            Author: <strong>{{ list.author }}</strong><br>
                            letzer Bearbeiter: <strong>{{ list.editor }}</strong><br>
                            Datum: <strong>{{ list.updatedAt | moment }}</strong>
                          </v-col>
                        </v-row>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              color="secondary"
                              v-if="!list.uncheckedItems"
                              @click="confirmArchive(list)"
                          >
                            Archivieren
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="openList(list)"
                          >
                            Anzeigen
                          </v-btn>
                        </v-card-actions>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="secondary"
                        @click="confirmArchiveAll(vehicle.plists)"
                    >
                      Alle archivieren
                    </v-btn>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
        v-model="dialog.confirmArchive"
        persistent
        max-width="290"
    >
      <v-card v-if="selectedList">
        <v-card-title class="headline">
          Checkliste {{ selectedList.title }} archivieren?
        </v-card-title>
        <v-card-text>Diese Checkliste definiv archivieren?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              text
              @click="dialog.confirmArchive = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="archiveList(selectedList)"
          >
            Archivieren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.confirmArchiveAll"
        persistent
        max-width="290"
    >
      <v-card v-if="archiveLists">
        <v-card-title class="headline">
          {{ archiveLists.length }} Listen archivieren?
        </v-card-title>
        <v-card-text>Diese Checklisten definiv archivieren?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              text
              @click="dialog.confirmArchiveAll = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="red darken-1"
              text
              @click="archiveAll()"
          >
            Archivieren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snack.state"
        :timeout="snack.timeout"
        :color="snack.color"
        elevation="12"
        top
        right
    >
      {{ snack.message }}
    </v-snackbar>


  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "Review",
  data() {
    return {
      vehicles: null,
      checklists: null,
      selectedList: null,
      archiveLists: [],
      panel: null,
      filters: [
        {
          name: 'Alle',
          key: 'all'
        },
        {
          name: 'Komplette',
          key: 'complete'
        },
        {
          name: 'Fehlerhafte',
          key: 'incomplete'
        }
      ],
      filter: 'all',
      dialog: {
        confirmArchive: false,
        confirmArchiveAll: false
      },
      snack: {
        state: false,
        color: 'success',
        timeout: 2000,
        message: 'Checkliste wurde erfolgreich gespeichert!',
      }
    }
  },
  created() {

  },
  mounted() {
    this.getVehicles()
  },
  filters: {
    moment: function (date) {
      return moment(date).format('DD.MM.YY HH:mm');
    }
  },
  methods: {
    getVehicles() {

      axios
          .get(process.env.VUE_APP_API_URL + '/pchecklists/vehicles/review/' + this.filter)
          .then((response) => {
            this.vehicles = response.data
          })
    },
    getItemCount(list) {

      let obj = {
        done: 0,
        error: 0
      }

      list.forEach(e => {
        if (e.uncheckedItems) {
          obj.error += 1
        } else {
          obj.done += 1
        }
      })
      return obj
    },
    openList(list) {
      this.$router.push({name: 'PopulateChecklist', params: {id: list._id, newList: 'false', review: 'true'}})

    },
    confirmArchive(list) {
      this.selectedList = list
      this.dialog.confirmArchive = true
    },
    confirmArchiveAll(lists) {
      this.archiveLists = lists
      this.dialog.confirmArchiveAll = true
    },
    archiveAll(){
      this.archiveLists.forEach((l) => {
        this.archiveList(l)
      })
      this.dialog.confirmArchiveAll = false
    },
    archiveList(list) {
      axios.patch(process.env.VUE_APP_API_URL + '/pchecklists/' + list._id, {state: 'archived'})
          .then((response) => {
            console.log(response)
            if (response.status === 200) {
              this.dialog.confirmArchive = false
              this.getVehicles()
              this.snack.message = 'Checkliste wurde erfolgreich archviert.'
              this.snack.state = true
            }
          })
    },


  }
}
</script>

<style scoped>

</style>